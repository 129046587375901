<script>
export default {
    setup(){
        const currentYear =  process.env.VUE_APP_YEAR || 2020;
        return{
            currentYear
        }
    }
}
</script>
<template>
<section>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1 class="mb-4 pb-4">Acerca de la plataforma</h1>
                <p>El <strong>Observatorio de Datos Abiertos en VIH Alianza Liderazgo en Positivo y Poblaciones Clave</strong> (también llamado Observadatos.org) impulsa el monitoreo y veeduría de la inversión en VIH en 11 países priorizados de la región. Esta plataforma es parte de la subvención <strong>"Promoviendo mejores condiciones de vida y derechos humanos de las personas con VIH y otras poblaciones clave, a través de servicios integrales, diferenciados y con mayores recursos para apoyar la sostenibilidad de la respuesta regional al VIH"</strong> (también llamada Subvención Multipaís ALEP y Poblaciones Clave) financiada por el <strong>Fondo Mundial para la lucha contra el VIH/SIDA, la tuberculosis y la malaria</strong>.</p>
                <p>La subvención inició durante octubre de 2019 con 11 países priorizados en América Latina, y es implementada por la <a href="https://america-latina.hivos.org/program/alianza-liderazgo-en-positivo-y-poblaciones-clave/" class="od_link">Alianza Liderazgo en Positivo (ALEP) conformada por seis redes regionales de personas con VIH y cuatro redes de Poblaciones Clave</a>. </p> 
                <p>Las redes de ALEP y las redes de poblaciones clave son las siguientes:</p>
                <p>Alianza Liderazgo en Positivo:</p>
                <ul>               
                    <li>Red Centroamericana de Personas con VIH – REDCA+</li>
                    <li>Red Latinoamericana de Personas con VIH – RedLa+</li>
                    <li>Coalición Internacional de Preparación para el Tratamiento América Latina y Caribe – ITPC/LATCA</li>
                    <li>Comunidad Internacional de Mujeres con VIH – ICW Latina</li>
                    <li>Movimiento Latinoamericano y del Caribe de Mujeres Positivas – MLCM+</li>
                    <li>Red Latinoamericana y del Caribe de Jóvenes Positivos – J+LAC</li>
                </ul>
                <p>Redes de otras poblaciones clave:</p>
                <ul>  
                    <li>Gay Latino, por los hombres que tienen sexo con hombres</li>
                    <li>Red Latinoamericana y del Caribe de Personas Trans – RedLacTrans</li>
                    <li>Red Latinoamericana de Personas que usan Drogas, LANPUD</li>
                    <li>Plataforma Latinoamericana de Personas que Ejercen el Trabajo Sexual - PLAPERTS</li>
                </ul>
                <p>La información disponible en la plataforma permite visualizar datos estadísticos sobre la situación del VIH en cada país, y sobre las cuatro poblaciones clave que acompañan la subvención. Como parte de las buenas prácticas que buscamos promover en nuestro módulo de publicaciones encontrarán estudios de relevancia para impulsar la incidencia y la abogacía de las personas con VIH y otras poblaciones clave. </p>
                <p>Asimismo es posible encontrar información sobre los estudios <strong>Megas</strong> (Medición del Gasto de la Respuesta Nacional ante el VIH y sida) y <strong>GAM</strong> (Monitoreo Global del SIDA), esto gracias a la colaboración con <strong>Onusida</strong>. Además se cuenta con datos sobre los contratos disponibles en seis de los países parte de la subvención, datos que en dos países se encuentran bajo el <strong>Estándar de Contrataciones Abiertas</strong>. También se presenta información para los cinco países en los cuales la calidad de los datos no nos permite -por ahora- establecer un proceso de monitoreo. De esta forma, <a href="https://observadatos.org/" class="od_link">Observadatos.org</a> es una invitación a abrir información y datos de calidad para las personas con VIH y poblaciones clave de la región. </p>
                <p>Los datos disponibles en esta plataforma son actualizados cada seis meses por comités nacionales de veeduría que se han gestionado de manera coordinada y en red. Esto garantiza la sostenibilidad de esta herramienta, centrada en el fortalecimiento de capacidades de las, los y les activistas que forman parte de las 10 redes. Buscamos a partir de esta experiencia que el acceso a datos de calidad sea una práctica más frecuente en la región que consolide los diálogos entre los Estados y Sociedad Civil. </p>
                <p>Agradecemos a todas las personas que permiten que esta plataforma se encuentre disponible. Y a quienes deseen sumarse a esta experiencia, les esperamos.</p>
                <p>Consulta las <router-link to="/notas-metodologicas" class="od_link">notas metodológicas</router-link> para más información.</p>
            </div>
        </div>
    </div>
</section>
</template>